import React, { useState, useEffect } from "react";
import EventsList from "../components/EventsList";
import Footer from "../components/Footer";
import Header from "../components/Header";

import SEO from "../components/seo";

// styles
const page = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}

const pageStyles = {
  color: "#232129",
  paddingTop: 60,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  display: "flex",
  flexDirection: "column",
  height: '100%',
  width: '100%'
}
const column = {
  maxWidth: '900px',
  padding: '12px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

}
const headingStyles = {
  marginTop: 0,
  marginBottom: 32,
  width: '100%',
  textAlign: 'center',
}
const eventsStyles = {
  marginTop: 32,
}
const donationLinks = {
  marginBottom: '100px'
}
const footer = {
  // position: 'absolute',
  // bottom: '0px',
  marginTop: '150px',
  padding: '20px 0px 20px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignContect: 'center',
}
const disclaimer = {
  width: '100%',
  textAlign: 'center'
}

// markup
const AboutPage = () => {

  const [ loading, setLoading ] = useState(true);
  const [ eventsList, setEventsList ] = useState([]);

  useEffect(() => {

    // Fetch data from DriveBC API
    fetch("https://api.open511.gov.bc.ca/events?road_name=Highway 5").then((res) => {
      res.json().then((json) => {
        const events = json.events.filter(event => event.severity == 'MAJOR');
        const newEventsList = [];

        // No major incidents from API
        if (!events || events.length == 0) {

          let status = {
            closed: "OPEN",
            direction: "BOTH",
            description: "No major incidents..."
          }

          newEventsList.push(status);

        } else {

          events.forEach((event) => {
            let direction = event.roads[0].direction;
            let description = event.description;
            let closed = description.toLowerCase().includes("road closed");

            if (closed) {

              let status = {
                closed: "CLOSED",
                direction: direction,
                description: description
              }
  
              newEventsList.push(status);
            }
          })
        }

        setEventsList(newEventsList);
      });


      setLoading(false);
    })

  }, []);

  return (
        <main style={pageStyles}>
          <SEO title="Home" />
          <div style={column}>
            <div>
              <Header />

              <h2>About</h2>

              <p>Coquihalla.today is your home for road closures along the coquihalla and other roads in the lower mainland. </p>

            </div>

            <Footer />
          </div>
        </main>
  )
}

export default AboutPage
